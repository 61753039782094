import { Flex } from 'components/common/Flex'
import { Subtitle, Title } from 'pages/Main/About/styled'

import { Container, Item, ItemSubtitle, ItemTitle } from './styled'

function ExploreMoreOfOurTopics() {
  return (
    <section>
      <Title>Explore More Of Our Topics</Title>
      <Flex column gap={16}>
        <Subtitle>
          Path4Life offers over 100 Torah topics to explore, including Hilchos Shabbos, Ahavas Yisrael, and inspiring
          stories of Gedolim. With new shiurim added daily, there’s always a fresh opportunity to learn and grow.
        </Subtitle>
        <Subtitle>
          Whether you’re looking to deepen your understanding of Hilchos Shabbos, strengthen your Bitachon, or find
          daily Chizuk to uplift your spirit, Path4Life provides something meaningful for everyone.
        </Subtitle>
        <Container>
          <Item>
            <ItemTitle>Tefilla</ItemTitle>
            <ItemSubtitle>Deepen your connection through prayer.</ItemSubtitle>
          </Item>
          <Item>
            <ItemTitle>Shalom Bayis</ItemTitle>
            <ItemSubtitle>Foster harmony and peace at home.</ItemSubtitle>
          </Item>
          <Item>
            <ItemTitle>Halacha</ItemTitle>
            <ItemSubtitle>Learn practical laws for daily life.</ItemSubtitle>
          </Item>
          <Item>
            <ItemTitle>Chinuch</ItemTitle>
            <ItemSubtitle>Find strategies for raising and teaching children</ItemSubtitle>
          </Item>
          <Item>
            <ItemTitle>Jewish History</ItemTitle>
            <ItemSubtitle>Connect to the rich history of the Jewish nation.</ItemSubtitle>
          </Item>
          <Item>
            <ItemTitle>And many more!</ItemTitle>
            <ItemSubtitle>Explore topics to inspire spiritual growth.</ItemSubtitle>
          </Item>
        </Container>
      </Flex>
    </section>
  )
}

export default ExploreMoreOfOurTopics
