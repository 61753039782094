import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 276px;
  padding: 20px;
  border: 1px solid #d9ecf6;
  border-radius: 12px;

  @media ${device.tablet} {
    max-width: 100%;
  }
`

export const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9ecf6;
  border-radius: 50%;
  background-color: #f4fbff;

  margin-bottom: 24px;
`

export const ItemTitle = styled.h3`
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  color: ${COLORS.text.default};

  margin-bottom: 12px;
`

export const ItemSubtitle = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.hint};
`
