import { ReactComponent as AmazonMusicIcon } from 'assets/icons/amazon-music.svg'
import { ReactComponent as ApplePodcastsIcon } from 'assets/icons/apple-podcasts.svg'
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg'
import { ReactComponent as SpotifyIcon } from 'assets/icons/spotify.svg'
import { ReactComponent as WhatsAppIcon } from 'assets/icons/whatsapp.svg'
import { AppStoreIcon, PlayMarketIcon } from 'components/ui/Sidebar/icons'
import { Title } from 'pages/Main/About/styled'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { ButtonsContainer, Container, Content, Item, ItemTitle, LinkButton, Text } from './styled'

function ChooseYourPath() {
  const navigate = useNavigate()

  return (
    <section>
      <Title>Choose Your Path to Torah Learning</Title>
      <Container>
        <Item>
          <ItemTitle>WEBSITE</ItemTitle>
          <Content>
            <Text>Sign Up online at www.thepath4life.com</Text>
            <ButtonsContainer>
              <LinkButton onClick={() => navigate(ROUTE_PATH.REGISTRATION)}>SIGN UP</LinkButton>
            </ButtonsContainer>
          </Content>
        </Item>
        <Item>
          <ItemTitle>MOBILE APPS</ItemTitle>
          <Content>
            <Text>Download the App for Android and iOS devices</Text>
            <ButtonsContainer>
              <LinkButton onClick={() => window.open(process.env.REACT_APP_GOOGLE_PLAY_LINK)}>
                <PlayMarketIcon />
              </LinkButton>
              <LinkButton onClick={() => window.open(process.env.REACT_APP_APP_STORE_LINK)}>
                <AppStoreIcon />
              </LinkButton>
            </ButtonsContainer>
          </Content>
        </Item>
        <Item big>
          <ItemTitle>PODCAST PLATFORMS</ItemTitle>
          <Content>
            <Text>Follow us on your preferred podcast platform to stay connected and never miss a shiur</Text>
            <ButtonsContainer column>
              <LinkButton
                onClick={() =>
                  window.open(
                    "https://open.spotify.com/search/Podcast%20The%20Path4Life%20-%20R'%20Nochum%20Malinowitz/podcasts"
                  )
                }
              >
                <SpotifyIcon />
                SPOTIFY
              </LinkButton>
              <LinkButton
                onClick={() =>
                  window.open(
                    'https://podcasts.apple.com/us/search?term=The%20Path4Life%20-%20R%27%20Nochum%20Malinowitz'
                  )
                }
              >
                <ApplePodcastsIcon />
                APPLE PODCASTS
              </LinkButton>
              <LinkButton
                onClick={() =>
                  window.open(
                    "https://music.amazon.com/search/R'+Nochum+Malinowitz/podcasts?filter=IsLibrary%7Cfalse&sc=none"
                  )
                }
              >
                <AmazonMusicIcon />
                AMAZON MUSIC
              </LinkButton>
            </ButtonsContainer>
          </Content>
        </Item>
        <Item>
          <ItemTitle>PHONE LINE</ItemTitle>
          <Content>
            <Text>Listen to Shiurim by calling</Text>
            <ButtonsContainer>
              <LinkButton onClick={() => window.open('tel:732-479-7323')}>
                <PhoneIcon />
                732-479-7323
              </LinkButton>
            </ButtonsContainer>
          </Content>
        </Item>
        <Item>
          <ItemTitle>WHATSAPP</ItemTitle>
          <Content>
            <Text>Join our WhatsApp groups</Text>
            <ButtonsContainer>
              <LinkButton onClick={() => window.open('https://wa.link/p6lqib')}>
                <WhatsAppIcon />
                JOIN
              </LinkButton>
            </ButtonsContainer>
          </Content>
        </Item>
      </Container>
    </section>
  )
}

export default ChooseYourPath
