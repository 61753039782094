import { ReactComponent as CloseIcon } from 'assets/icons/cross.svg'
import { Flex } from 'components/common/Flex'

import PeriodFilter from '../PeriodFilter'
import PlayedFilter from '../PlayedFilter'
import SeriesFilter from '../SeriesFilter'
import SubtopicFilter from '../SubtopicFilter'
import TopicFilter from '../TopicFilter'
import { ShiurimFilters } from '../index'
import { DownArrow, ResetButton, SubmitButton } from '../styled'
import { CollapseStyled, Wrapper } from './styled'

type Props = {
  filters: ShiurimFilters
  noTopics?: boolean
  noSeries?: boolean
  open: boolean
  onClose: () => void
  onReset: () => void
  onApply: () => void
  onChangeFilters: (filters: ShiurimFilters) => void
}

function FilterDrawer({ filters, noTopics, noSeries, open, onClose, onReset, onApply, onChangeFilters }: Props) {
  const items = [
    {
      key: '1',
      label: 'Played',
      children: <PlayedFilter filters={filters} onChangeFilters={onChangeFilters} />,
    },
    {
      key: '2',
      label: 'Period',
      children: <PeriodFilter filters={filters} onChangeFilters={onChangeFilters} />,
    },
    ...(noSeries
      ? []
      : [
          {
            key: '3',
            label: 'Series',
            children: <SeriesFilter filters={filters} onChangeFilters={onChangeFilters} />,
          },
        ]),
    ...(noTopics
      ? []
      : [
          {
            key: '4',
            label: 'Topic',
            children: <TopicFilter filters={filters} onChangeFilters={onChangeFilters} />,
          },
        ]),
    {
      key: '5',
      label: 'Sub-topic',
      children: <SubtopicFilter filters={filters} noTopics={noTopics} onChangeFilters={onChangeFilters} />,
    },
  ]

  const filterCount = Object.values(filters).filter((el) => {
    if (Array.isArray(el)) {
      return el.length > 0
    }
    return !!el
  }).length

  return (
    <Wrapper
      title="Filters"
      placement="right"
      width={360}
      footer={
        filterCount ? (
          <Flex alignCenter>
            <SubmitButton type="primary" onClick={onApply}>
              APPLY {filterCount} FILTERS
            </SubmitButton>
            <ResetButton onClick={onReset}>RESET ALL</ResetButton>
          </Flex>
        ) : (
          <SubmitButton type="primary" disabled>
            NO FILTERS SELECTED
          </SubmitButton>
        )
      }
      closeIcon={<CloseIcon />}
      onClose={onClose}
      open={open}
    >
      <CollapseStyled
        ghost
        expandIconPosition="end"
        expandIcon={({ isActive }) => <DownArrow isActive={isActive} />}
        items={items}
        defaultActiveKey={['1', '2', '3', '4']}
      />
    </Wrapper>
  )
}

export default FilterDrawer
