import { Button } from 'antd'
import backgroundSrc from 'assets/img/greeting-background.png'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 8px;

  height: 180px;
  padding: 40px 60px 40px 40px;
  margin-bottom: 40px;

  background-image: url(${backgroundSrc});
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;

  border: 0.5px solid #d9ecf6;
  border-radius: 12px;

  @media ${device.tablet} {
    flex-direction: column;
    height: auto;
    padding: 12px 20px 12px 12px;
    margin-bottom: 20px;
  }
`

export const Title = styled.p`
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
  color: ${COLORS.text.default};

  margin-bottom: 20px;

  @media ${device.tablet} {
    font-size: 24px;

    margin-bottom: 8px;
  }
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: #677fa9;
`

export const ActionButton = styled(Button)`
  width: 176px;
  height: 50px;

  line-height: inherit;

  @media ${device.tablet} {
    width: 100%;
    height: 45px;
    line-height: 0;
  }
`
