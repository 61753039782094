import bitachonImgSrc from 'assets/img/BitachonTitle.png'
import dailyDafYomiImgSrc from 'assets/img/DailyDafYomiTitle.png'
import parshaForLifeImgSrc from 'assets/img/ParshaForLifeTitle.png'
import shabbosForLifeImgSrc from 'assets/img/ShabbosForLifeTitle.png'
import { Flex } from 'components/common/Flex'
import { Title } from 'pages/Main/About/styled'

import { Container, Image, Item, ItemSubtitle, ItemTitle } from './styled'

function MostPopularShiurim() {
  return (
    <section>
      <Title>Most Popular Shiurim</Title>
      <Flex column gap={16}>
        <Container>
          <Item>
            <Image src={bitachonImgSrc} />
            <Flex column gap={8}>
              <ItemTitle>Bitachon4Life</ItemTitle>
              <ItemSubtitle>
                Our most popular program, Bitachon4Life, inspires thousands of daily listeners with empowering messages
                and practical tools for building trust in Hashem.
              </ItemSubtitle>
            </Flex>
          </Item>
          <Item>
            <Image src={dailyDafYomiImgSrc} />
            <Flex column gap={8}>
              <ItemTitle>Daily Daf Yomi</ItemTitle>
              <ItemSubtitle>
                Join a dynamic Daf Yomi shiur that makes the complexities of the Talmud accessible and engaging. Each
                session is delivered with clarity and warmth, ensuring listeners feel connected—no matter where they
                are.
              </ItemSubtitle>
            </Flex>
          </Item>
          <Item>
            <Image src={shabbosForLifeImgSrc} />
            <Flex column gap={8}>
              <ItemTitle>Shabbos4Life</ItemTitle>
              <ItemSubtitle>
                Fascinating insights into the gift of Shabbos. Something to share with family and friends to elevate and
                inspire
              </ItemSubtitle>
            </Flex>
          </Item>
          <Item>
            <Image src={parshaForLifeImgSrc} />
            <Flex column gap={8}>
              <ItemTitle>Parsha4Life</ItemTitle>
              <ItemSubtitle>
                Gain a deeper understanding of the weekly parsha with short and sweet divrei torah.
              </ItemSubtitle>
            </Flex>
          </Item>
        </Container>
      </Flex>
    </section>
  )
}

export default MostPopularShiurim
