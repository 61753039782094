import AlertModal from 'components/ui/Modal/AlertModal'
import { deviceWidth } from 'constants/responsive'
import dayjs from 'dayjs'
import { updateShiurStats, updateShiurStatsVariables } from 'graphql/mutation/__generated__/updateShiurStats'
import { UPDATE_SHIUR_STATS } from 'graphql/mutation/updateShiurStats'
import { convertSecsInMs } from 'helpers/convertSecsInMs'
import throttle from 'helpers/throttle'
import { useAuth } from 'hooks/useAuth'
import { useDownloadAudio } from 'hooks/useDownloadAudio'
import { usePlayer } from 'hooks/usePlayer'
import { usePlayerModals } from 'hooks/usePlayerModals'
import { defaultPlaybackRate, usePlayerOptions } from 'hooks/usePlayerOptions'
import { useCallback, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useMutation } from '@apollo/client'

import AddNoteBookmarkModal from '../Modals/AddNoteBookmarkModal'
import PlaybackRateModal from '../Modals/PlaybackRateModal'
import ShareShiurimModal from '../Modals/ShareShiurimModal'
import ShiurimInfoModal from '../Modals/ShiurimInfoModal'
import PlayerAdditionalActions from './PlayerAdditionalActions'
import PlayerShiurInfo from './PlayerShiurInfo'
import { MoveBack, MoveForward, PauseIcon, PlayIcon } from './icons'
import {
  AutomaticallyPlayButton,
  ControlsWrapper,
  DurationText,
  DurationWrapper,
  MainButton,
  NextButton,
  PlaybackRateText,
  PlayerWrapper,
  PrevButton,
  SliderProgressLine,
  Wrapper,
} from './styled'

function Player() {
  const { setPlayerRef } = usePlayer()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { user } = useAuth()
  const playerRef = useRef<ReactPlayer>(null)

  useEffect(() => {
    if (playerRef.current) setPlayerRef(playerRef)
  }, [])

  const [updateShiurStats] = useMutation<updateShiurStats, updateShiurStatsVariables>(UPDATE_SHIUR_STATS)

  const { isPlaying, url, shiur, isAutoPlayEnabled, positionInQueue, queue, previous, next, toggleAutoPlay } =
    usePlayer()
  const {
    duration,
    setDuration,
    progress,
    setProgress,
    volume,
    setVolume,
    playbackRate,
    changePlaybackRate,
    resetPlaybackRate,
    onPlay,
    onPause,
    onMoveBack,
    onMoveForward,
    moveTo,
  } = usePlayerOptions(playerRef)

  const { download, abortDownload, downloadState, downloadingProgress } = useDownloadAudio(shiur.title, url)

  const {
    isRegisterToContinueModalOpen,
    openRegisterToContinueModal,
    closeRegisterToContinueModal,
    isInfoModalOpen,
    onOpenInfoModal,
    onCloseInfoModal,
    isPlaybackRateModalOpen,
    onOpenPlaybackRateModal,
    onClosePlaybackRateModal,
    isAddNoteBookmarkModalOpen,
    onOpenAddNoteBookmarkModal,
    onCloseAddNoteBookmarkModal,
    isShareModalOpen,
    onOpenShareModal,
    onCloseShareModal,
  } = usePlayerModals()

  const onChangePlaybackRate = (playbackRate: number) => {
    changePlaybackRate(playbackRate)
    onClosePlaybackRateModal()
  }

  const onResetPlaybackRate = () => {
    resetPlaybackRate()
    onClosePlaybackRateModal()
  }

  const onStart = async () => {
    if (user) await updateShiurStats({ variables: { shiurId: shiur.shiurId } })
  }

  const onEnded = async () => {
    if (user)
      await updateShiurStats({
        variables: { shiurId: shiur.shiurId, duration: Math.floor(convertSecsInMs(progress.playedSeconds)) },
      })

    next()
  }

  const updateProgressOnBackEnd = useCallback(
    throttle(async (playedSeconds: number) => {
      if (user)
        await updateShiurStats({
          variables: { shiurId: shiur.shiurId, duration: Math.floor(convertSecsInMs(playedSeconds)) },
        })
    }, 5000),
    [user, shiur.shiurId]
  )

  useEffect(() => updateProgressOnBackEnd(progress.playedSeconds), [progress])

  const isPrevDisabled = positionInQueue <= 1 || queue.length === 0
  const isNextDisabled = positionInQueue >= queue.length || queue.length === 0

  const isTabletOrLess = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <>
      <Wrapper>
        <PlayerShiurInfo onOpenInfoModal={onOpenInfoModal} />
        <PlayerWrapper>
          <ControlsWrapper>
            {!isTabletOrLess && (
              <PlaybackRateText
                isChanged={playbackRate !== defaultPlaybackRate}
                onClick={user ? onOpenPlaybackRateModal : openRegisterToContinueModal}
              >
                {playbackRate.toFixed(1)}x
              </PlaybackRateText>
            )}
            {!isTabletOrLess && <PrevButton disabled={isPrevDisabled} onClick={previous} />}
            <MoveBack onClick={onMoveBack} />
            <MainButton onClick={isPlaying ? onPause : onPlay}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</MainButton>
            <MoveForward onClick={onMoveForward} />
            {!isTabletOrLess && <NextButton disabled={isNextDisabled} onClick={next} />}
            {!isTabletOrLess && (
              <AutomaticallyPlayButton
                disabled={pathname === ROUTE_PATH.HISTORY}
                enabled={isAutoPlayEnabled}
                onClick={pathname === ROUTE_PATH.HISTORY ? undefined : toggleAutoPlay}
              />
            )}
          </ControlsWrapper>
          <DurationWrapper>
            <DurationText>{dayjs.duration(progress.playedSeconds, 's').format('m:ss')}</DurationText>
            <SliderProgressLine
              tooltip={{ open: false }}
              max={duration || 100}
              value={progress.playedSeconds || 0}
              onChange={(value: number) => moveTo(value)}
            />
            <DurationText>-{dayjs.duration(duration - progress.playedSeconds, 's').format('m:ss')}</DurationText>
          </DurationWrapper>
        </PlayerWrapper>

        <PlayerAdditionalActions
          onOpenAddNoteBookmarkModal={onOpenAddNoteBookmarkModal}
          onOpenPlaybackRateModal={onOpenPlaybackRateModal}
          downloadState={downloadState}
          downloadingProgress={downloadingProgress}
          download={download}
          abortDownload={abortDownload}
          playbackRate={playbackRate}
          volume={volume}
          setVolume={setVolume}
          share={onOpenShareModal}
          openRegisterToContinueModal={openRegisterToContinueModal}
        />
      </Wrapper>

      {/* Hidden, we need it to play audio */}
      <ReactPlayer
        ref={playerRef}
        width={0}
        height={0}
        url={`${process.env.REACT_APP_AWS_S3_URL}${url}`}
        playing={isPlaying}
        volume={volume / 100}
        playbackRate={playbackRate}
        onStart={onStart}
        onDuration={setDuration}
        onProgress={setProgress}
        onEnded={onEnded}
        progressInterval={500}
      />

      <AlertModal
        open={isRegisterToContinueModalOpen}
        onCancel={closeRegisterToContinueModal}
        onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
        onSecondaryBtnClick={closeRegisterToContinueModal}
        title="Register to Continue"
        description="To unlock all features you need to create an account"
        primaryBtnText="REGISTER"
        secondaryBtnText="NOT NOW"
      />

      {isInfoModalOpen && <ShiurimInfoModal open={isInfoModalOpen} onClose={onCloseInfoModal} {...shiur} />}
      {isPlaybackRateModalOpen && (
        <PlaybackRateModal
          selectedPlaybackRate={playbackRate}
          open={isPlaybackRateModalOpen}
          onClose={onClosePlaybackRateModal}
          onChangePlaybackRate={onChangePlaybackRate}
          onResetPlaybackRate={onResetPlaybackRate}
        />
      )}
      {isAddNoteBookmarkModalOpen && (
        <AddNoteBookmarkModal open={isAddNoteBookmarkModalOpen} onClose={onCloseAddNoteBookmarkModal} />
      )}
      {isShareModalOpen && <ShareShiurimModal open={isShareModalOpen} onClose={onCloseShareModal} {...shiur} />}
    </>
  )
}

export default Player
