import { Flex } from 'components/common/Flex'
import { Subtitle, Title } from 'pages/Main/About/styled'

function HowToListen() {
  return (
    <section>
      <Title>How to Listen</Title>
      <Flex column gap={16}>
        <Subtitle>
          Path4Life makes Torah learning accessible anytime, anywhere. You can listen through our intuitive website,
          user-friendly mobile apps, dedicated phone line, WhatsApp groups, or popular podcast platforms. Wherever you
          are, Path4Life ensures you can connect to Torah learning with ease.
        </Subtitle>
        <Subtitle>
          Our platform is designed to fit seamlessly into your daily routine, offering features like adjustable playback
          speeds and an easy-to-navigate search functionality to help you find the shiurim that speak to you.
        </Subtitle>
      </Flex>
    </section>
  )
}

export default HowToListen
