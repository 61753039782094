import { Button } from 'antd'
import { Flex } from 'components/common/Flex'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
`

export const Item = styled.div<{ big?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #eaf7fe;
  border: 0.5px solid #d9ecf6;
  border-radius: 12px;

  ${({ big }) => big && 'grid-row: span 2;'}

  @media ${device.laptop} {
    width: 100%;
  }
`

export const ItemTitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${COLORS.primary.medium};

  padding: 16px 0;
  background: #d2f0ff;

  @media ${device.tablet} {
    padding: 8px 0;
  }
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 20px 16px;
  text-align: center;
`

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: #677fa9;
`

export const ButtonsContainer = styled(Flex)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 180px;
`

export const LinkButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
  height: 42px;
  font-weight: 600;
  line-height: 1px;
`
