import { Flex } from 'components/common/Flex'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { ActionButton, Subtitle, Title, Wrapper } from './styled'

function Banner() {
  const { user } = useAuth()

  const navigate = useNavigate()

  const onClick = () => {
    navigate(ROUTE_PATH.REGISTRATION)
  }

  return (
    <>
      <Wrapper>
        <Flex alignCenter>
          <div>
            <Title>Your Path to Torah Learning Made Simple</Title>
            <Subtitle>
              Created to inspire and uplift, Path4Life reaches learners from all backgrounds, bringing timeless Torah
              insights to wherever you are.
            </Subtitle>
          </div>
        </Flex>
        {!user && (
          <ActionButton type="primary" onClick={onClick}>
            JOIN PATH4LIFE
          </ActionButton>
        )}
      </Wrapper>
    </>
  )
}

export default Banner
