import aboutImgTwoSrc from 'assets/img/about-img-two.png'
import { Flex } from 'components/common/Flex'
import { deviceWidth } from 'constants/responsive'
import { Subtitle, Title } from 'pages/Main/About/styled'

import { Image } from './styled'

function MoreThanJustLearning() {
  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <section>
      <Flex gap={14} column={isMobile}>
        <div>
          <Title>More Than Just Learning</Title>
          <Flex column gap={16}>
            <Subtitle>
              Path4Life is about more than Torah learning—it’s about building a community of Yidden united by their
              shared desire to grow and strengthen their connection to Hashem.
            </Subtitle>
            <Subtitle>
              With thousands of regular listeners from across the globe and new shiurim added daily, Path4Life has
              become a true beacon of inspiration. Offering practical, heartfelt guidance, it continues to illuminate
              the path of Torah for Jews everywhere, empowering them to live with greater meaning and connection.
            </Subtitle>
          </Flex>
        </div>
        <Image src={aboutImgTwoSrc} />
      </Flex>
    </section>
  )
}

export default MoreThanJustLearning
