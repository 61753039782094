import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Image = styled.img`
  object-fit: contain;
  border-radius: 12px;
  max-width: 277px;

  margin-bottom: 10px;

  @media ${device.tablet} {
    width: 100%;
    max-width: 400px;
  }
`

export const ImageSubtitle = styled.p`
  font-size: 12px;
  line-height: 1.2;
  font-style: italic;
  color: #696b7b;
`
