import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Image = styled.img`
  object-fit: contain;
  border-radius: 12px;
  max-width: 418px;

  @media ${device.tablet} {
    width: 100%;
    max-width: 400px;
  }
`
