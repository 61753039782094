import Banner from 'components/business/Main/About/Banner'
import ChooseYourPath from 'components/business/Main/About/ChooseYourPath'
import ExploreMoreOfOurTopics from 'components/business/Main/About/ExploreMoreOfOurTopics'
import HowToListen from 'components/business/Main/About/HowToListen'
import MoreThanJustLearning from 'components/business/Main/About/MoreThanJustLearning'
import MostPopularShiurim from 'components/business/Main/About/MostPopularShiurim'
import OurMission from 'components/business/Main/About/OurMission'
import WhatIsPathForLife from 'components/business/Main/About/WhatIsPathForLife'
import Header from 'components/ui/Header'
import { ReactComponent as AboutIcon } from 'components/ui/Sidebar/icons/active/about.svg'

import { Wrapper } from '../styled'
import { Container, Link, Subtitle } from './styled'

function About() {
  return (
    <>
      <Header icon={<AboutIcon />} title="About" />
      <Wrapper>
        <Banner />
        <Container>
          <WhatIsPathForLife />
          <OurMission />
          <MostPopularShiurim />
          <ExploreMoreOfOurTopics />
          <MoreThanJustLearning />
          <HowToListen />
          <ChooseYourPath />
          <Subtitle>
            For more information visit our website at{' '}
            <Link href="https://www.thepath4life.com">www.thepath4life.com</Link> or contact us by email:{' '}
            <Link href="mailto:support@thepath4life.com">support@thepath4life.com</Link>
          </Subtitle>
        </Container>
      </Wrapper>
    </>
  )
}

export default About
