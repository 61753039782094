import { ReactComponent as AccessibleIcon } from 'assets/icons/accessible.svg'
import { ReactComponent as ConciseIcon } from 'assets/icons/concise.svg'
import { ReactComponent as ImpactfulIcon } from 'assets/icons/impactful.svg'
import { Flex } from 'components/common/Flex'
import { deviceWidth } from 'constants/responsive'
import { Subtitle, Title } from 'pages/Main/About/styled'

import { IconWrapper, Item, ItemSubtitle, ItemTitle } from './styled'

function OurMission() {
  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <section>
      <Title>Our Mission</Title>
      <Flex column gap={16}>
        <Subtitle>
          Our mission is to spread Chizuk and inspire Yidden around the world by making Torah learning easily accessible
          anywhere, anytime. To achieve this, we’ve developed an intuitive app designed to fit seamlessly into your
          daily life, offering features such as searchable shiurim, customizable playback speeds, and the ability to
          follow your favorite topics.
        </Subtitle>
        <Subtitle>
          Whether you’re at home, or on the go, or just looking for a few moments of inspiration, Path4Life brings the
          timeless wisdom of Torah to your fingertips—empowering you to learn and be inspired at your convenience.
        </Subtitle>
        <Flex column={isMobile} gap={16}>
          <Item>
            <IconWrapper>
              <AccessibleIcon />
            </IconWrapper>
            <ItemTitle>Accessible</ItemTitle>
            <ItemSubtitle>At home or on the go Path4Life is easy to listen to across multiple platforms</ItemSubtitle>
          </Item>
          <Item>
            <IconWrapper>
              <ImpactfulIcon />
            </IconWrapper>
            <ItemTitle>Impactful</ItemTitle>
            <ItemSubtitle>The Shiurim provide practical, meaningful lessons that will enhance your life.</ItemSubtitle>
          </Item>
          <Item>
            <IconWrapper>
              <ConciseIcon />
            </IconWrapper>
            <ItemTitle>Concise</ItemTitle>
            <ItemSubtitle>
              Most Shiurim are only four minutes, allowing you to fit learning into your busy schedule.
            </ItemSubtitle>
          </Item>
        </Flex>
      </Flex>
    </section>
  )
}

export default OurMission
