import { ReactComponent as AddToQueueIcon } from 'assets/icons/add-to-queue.svg'
import { ReactComponent as MoreIconBig } from 'assets/icons/dots-big.svg'
import { ReactComponent as MoreIcon } from 'assets/icons/dots.svg'
import { ReactComponent as PlayIconBig } from 'assets/icons/play-big.svg'
import { ReactComponent as PlayIconMedium } from 'assets/icons/play-medium.svg'
import { ReactComponent as PlayIconSmall } from 'assets/icons/play-small.svg'
import { ReactComponent as PlayingIcon } from 'assets/icons/playing.svg'
import { ReactComponent as SavedIcon } from 'assets/icons/saved.svg'
import { ReactComponent as PlayedIcon } from 'assets/icons/tick-circle.svg'
import { Flex } from 'components/common/Flex'
import { ReactComponent as SaveIcon } from 'components/ui/Sidebar/icons/inactive/saved.svg'
import { COLORS } from 'constants/colors'
import { deviceWidth } from 'constants/responsive'
import dayjs from 'dayjs'
import { getAllUserShiurListenings_getAllUserShiurListenings_items } from 'graphql/query/__generated__/getAllUserShiurListenings'
import { getShiurimFromFollowed_getShiurimFromFollowed_items } from 'graphql/query/__generated__/getShiurimFromFollowed'
import { convertMsInSecs } from 'helpers/convertMsInSecs'
import { getDateWithoutTimezone } from 'helpers/getDateWithoutTimezone'
import { useAuth } from 'hooks/useAuth'
import { usePlayer } from 'hooks/usePlayer'
import { QueryInfo, useQueryInfo } from 'hooks/useQueryInfo'
import { MouseEvent, memo, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ShiurActionsPopover from '../../Shiurim/ShiurActionsPopover'
import {
  ActionsContainer,
  ButtonsContainer,
  ContentWrapper,
  Description,
  Image,
  LeftWrapper,
  Number as OrderedNumber,
  PlayButtonWrapper,
  PlayerDescription,
  PlayerWrapper,
  ProgressLine,
  SeriesTitle,
  Subtitle,
  Title,
  TitleRow,
  Wrapper,
} from './styled'

export type ShiurimSize = 'small' | 'big'
export type ShiurimItem = Omit<getShiurimFromFollowed_getShiurimFromFollowed_items, '__typename'> & {
  __typename: any
} & Partial<Pick<getAllUserShiurListenings_getAllUserShiurListenings_items, 'listenedAt'>>

export type ShiurimAutoPlayInfo = {
  index: number
} & QueryInfo

type Props = {
  size?: ShiurimSize
  history?: boolean
  openRegisterToContinueModal: () => void
  onToggleFavorite: (shiurId: string, favorite: boolean) => Promise<void>
  index: number
} & ShiurimItem

function ShiurimCard({ size, history, openRegisterToContinueModal, onToggleFavorite, index, ...shiur }: Props) {
  const navigate = useNavigate()

  const { queryInfo } = useQueryInfo()
  const [shiurQueryInfo, setShiurQueryInfo] = useState(queryInfo)

  useEffect(() => {
    if (!shiurQueryInfo) setShiurQueryInfo(queryInfo)
  }, [queryInfo, shiurQueryInfo])

  const { id, isPlaying, duration: playerDuration, progress, playerRef, play, pause, addToQueue } = usePlayer()
  const { user } = useAuth()

  const moreButtonElRef = useRef<HTMLDivElement>()

  const isCurrentInitialized = id === shiur.shiurId
  const isCurrentPlaying = isPlaying && isCurrentInitialized

  const shiurimAutoPlayInfo: ShiurimAutoPlayInfo = { ...shiurQueryInfo, index }

  useEffect(() => {
    if (
      shiur.listenedDuration &&
      dayjs.duration(shiur.duration - shiur.listenedDuration).asSeconds() > 1 &&
      isCurrentInitialized &&
      playerRef?.current
    ) {
      playerRef.current.seekTo(Math.round(convertMsInSecs(shiur.listenedDuration)), 'seconds')
    }
  }, [isCurrentInitialized, playerRef])

  const togglePlayer = async (e: MouseEvent<HTMLDivElement>) => {
    // Prevent play/pause if more button clicked
    if (moreButtonElRef.current.children[0] === e.target || moreButtonElRef.current === e.target) return

    if (isPlaying && isCurrentInitialized) {
      return pause()
    }

    if (isCurrentInitialized && !isPlaying) {
      return await play()
    }

    if (!isCurrentInitialized) {
      await play(shiur, shiurimAutoPlayInfo)
    }
  }

  const onSaveToFavorite = async (e: MouseEvent) => {
    e.stopPropagation()

    if (!user) return openRegisterToContinueModal()

    await onToggleFavorite(shiurId, favoriteInfo.isFavorite)
  }

  const onAddToQueue = (e: Event) => {
    e.stopPropagation()

    if (!user) return openRegisterToContinueModal()

    addToQueue(shiur, shiurimAutoPlayInfo)
  }

  const onMoreIconClickUnAuthorized = (e: MouseEvent) => {
    e.stopPropagation()

    openRegisterToContinueModal()
  }

  const goToSeries = (e: MouseEvent) => {
    e.stopPropagation()

    navigate(`/series/${shiur.series.seriesId}`)
  }

  const {
    shiurId,
    orderedNum,
    title,
    subtitle,
    series,
    topic,
    duration,
    listenedDuration,
    favoriteInfo,
    publicationDate,
    listenedAt,
  } = shiur

  const isSmall = size === 'small'
  const isBig = size === 'big'

  const isMobile = window.innerWidth < Number(deviceWidth.laptop.match(/(\d+)/)[0])
  return (
    <Wrapper
      small={isSmall}
      big={isBig}
      playing={isPlaying && shiurId === id}
      listened={!!listenedDuration}
      onClick={togglePlayer}
    >
      <Flex justifyBetween>
        <LeftWrapper>
          <Image small={isSmall} big={isBig} src={`${process.env.REACT_APP_AWS_S3_URL}${series.coverUrl}`} />
          <ContentWrapper small={isSmall} big={isBig}>
            <TitleRow small={isSmall} big={isBig}>
              {!!orderedNum && (
                <OrderedNumber small={isSmall} big={isBig}>
                  Shiur {orderedNum}.
                </OrderedNumber>
              )}
              <Title small={isSmall} big={isBig}>
                {title}
              </Title>
            </TitleRow>
            {subtitle && (
              <Subtitle small={isSmall} big={isBig}>
                {subtitle}
              </Subtitle>
            )}
            {!isSmall && (
              <Description small={isSmall} big={isBig}>
                <SeriesTitle onClick={goToSeries} color={series.titleColor}>
                  {series.title}
                </SeriesTitle>
                {`∙${topic.title}`}
              </Description>
            )}
            <Flex alignCenter>
              <PlayerWrapper>
                <PlayButtonWrapper small={isSmall} big={isBig}>
                  {isCurrentPlaying ? (
                    <PlayingIcon />
                  ) : isBig ? (
                    <PlayIconBig />
                  ) : isSmall ? (
                    <PlayIconSmall />
                  ) : (
                    <PlayIconMedium />
                  )}
                </PlayButtonWrapper>
                <PlayerDescription small={isSmall} big={isBig}>
                  {listenedDuration &&
                  dayjs.duration(duration - listenedDuration).asSeconds() < 15 &&
                  !isCurrentPlaying ? (
                    <Flex alignCenter>
                      Played&nbsp;
                      <PlayedIcon />
                    </Flex>
                  ) : (
                    <>
                      {getDateWithoutTimezone(history ? listenedAt : publicationDate)
                        .toDate()
                        .toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}{' '}
                      ·{' '}
                      {isCurrentInitialized
                        ? `${dayjs
                            .duration(playerDuration - progress?.playedSeconds || convertMsInSecs(duration), 's')
                            .format('m:ss')} min left`
                        : listenedDuration
                        ? `${dayjs
                            .duration(convertMsInSecs(duration - listenedDuration), 'seconds')
                            .format('m:ss')} min left`
                        : `${dayjs.duration(convertMsInSecs(duration), 'seconds').format('m:ss')} min`}
                    </>
                  )}
                </PlayerDescription>
              </PlayerWrapper>
              {isCurrentInitialized && (
                <ProgressLine
                  showInfo={false}
                  strokeColor={COLORS.primary.dark}
                  trailColor="#D9ECF6"
                  percent={progress?.played * 100}
                />
              )}
            </Flex>
          </ContentWrapper>
        </LeftWrapper>

        <ActionsContainer small={isSmall} big={isBig}>
          <ShiurActionsPopover
            shiur={shiur}
            index={index}
            full={isMobile ? true : isBig || isSmall}
            onSaveToFavorite={onSaveToFavorite}
          >
            {isBig ? (
              <MoreIconBig ref={moreButtonElRef} onClick={user ? undefined : onMoreIconClickUnAuthorized} />
            ) : (
              <MoreIcon ref={moreButtonElRef} onClick={user ? undefined : onMoreIconClickUnAuthorized} />
            )}
          </ShiurActionsPopover>
          {!isMobile && !isBig && !isSmall && (
            <ButtonsContainer>
              {favoriteInfo.isFavorite ? (
                <SavedIcon onClick={onSaveToFavorite} />
              ) : (
                <SaveIcon onClick={onSaveToFavorite} />
              )}
              <AddToQueueIcon onClick={onAddToQueue} />
            </ButtonsContainer>
          )}
        </ActionsContainer>
      </Flex>
    </Wrapper>
  )
}

export default memo(ShiurimCard)
