import aboutImgOneSrc from 'assets/img/about-img-one.png'
import { Flex } from 'components/common/Flex'
import { deviceWidth } from 'constants/responsive'
import { Subtitle, Title } from 'pages/Main/About/styled'

import { Image, ImageSubtitle } from './styled'

function WhatIsPathForLife() {
  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <section>
      <Title>What is Path4Life</Title>
      <Flex gap={isMobile ? 30 : 68} column={isMobile}>
        <Flex column gap={16}>
          <Subtitle>
            Path4Life is a transformative Torah learning platform, founded by Rabbi Nochum Malinowitz, who carries
            forward the inspiring legacy of his father, Rabbi Shlomo Zalman Malinowitz.
          </Subtitle>
          <Subtitle>
            Rabbi Nochum Malinowitz, a world-renowned Talmid Chacham and the Rov of Liberty Village in Toms River, is
            widely respected for his unique ability to uplift people from all walks of life and help them strengthen
            their connection to Hashem. His warmth, insight, and dedication have touched countless lives, making Torah
            learning accessible and inspiring for everyone.
          </Subtitle>
          <Subtitle>
            What began as a daily Parsha shiur in memory of his father has grown into a vibrant platform offering daily
            shiurim on over fifty Torah topics. Path4Life caters to a diverse audience of men, women, and young adults,
            providing meaningful inspiration for Yidden from all backgrounds.
          </Subtitle>
        </Flex>
        <div>
          <Image src={aboutImgOneSrc} />
          <ImageSubtitle>Rabbi Nochum Malinowitz</ImageSubtitle>
        </div>
      </Flex>
    </section>
  )
}

export default WhatIsPathForLife
