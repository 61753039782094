import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 77px;

  max-width: 850px;
  margin-left: auto;
  margin-right: auto;

  @media ${device.tablet} {
    gap: 40px;
  }
`

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;

  margin-bottom: 24px;
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: #696b7b;
`

export const Link = styled.a`
  font-weight: 600;
  color: #0f1653;
`
