import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
`

export const Item = styled.div`
  display: flex;
  gap: 16px;
  max-width: 425px;
  min-width: 226px;
  padding: 20px;
  border: 1px solid #d9ecf6;
  border-radius: 12px;

  @media ${device.tablet} {
    max-width: 100%;
  }
`
export const Image = styled.img`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 8px;
`

export const ItemTitle = styled.h3`
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  color: ${COLORS.text.default};
`

export const ItemSubtitle = styled.p`
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.hint};
`
