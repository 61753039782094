import styled from 'styled-components'

interface FlexProps {
  fullWidth?: boolean
  inline?: boolean
  flexOne?: boolean
  justifyCenter?: boolean
  justifyBetween?: boolean
  justifyAround?: boolean
  justifyEnd?: boolean
  alignCenter?: boolean
  alignStart?: boolean
  alignBaseline?: boolean
  alignEnd?: boolean
  flexEnd?: boolean
  wrap?: boolean
  column?: boolean
  gap?: number
}

export const Flex = styled.div<FlexProps>`
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  ${({ inline }) => (inline ? 'display: inline-flex;' : 'display: flex;')}
  ${({ flexOne }) => flexOne && 'flex: 1;'}

  ${({ justifyBetween }) => justifyBetween && 'justify-content: space-between;'}
  ${({ justifyCenter }) => justifyCenter && 'justify-content: center;'}
  ${({ justifyAround }) => justifyAround && 'justify-content: space-around;'}
  ${({ justifyEnd }) => justifyEnd && 'justify-content: flex-end;'}
  ${({ flexEnd }) => flexEnd && 'justify-content: flex-end;'}
  ${({ alignCenter }) => alignCenter && 'align-items: center;'}
  ${({ alignBaseline }) => alignBaseline && 'align-items: baseline;'}
  ${({ alignStart }) => alignStart && 'align-items: flex-start;'}
  ${({ alignEnd }) => alignEnd && 'align-items: flex-end;'}
  ${({ wrap }) => wrap && 'flex-wrap: wrap;'}
  ${({ column }) => column && 'flex-direction: column;'}
  ${({ gap }) => gap && `gap: ${gap}px;`}
`
